// export const BASE_URL = 'https://al-electronics.uz/'

export const SUPPORT_LOCALES = ["en", "ru", "uz"];
// export const SUPPORT_LOCALES = ['en', 'ru', 'uz', 'uz_to', 'ar', 'cn', 'de', 'es', 'fr', 'jp', 'tk']

export const SUPPORT_LOCALES_NAMES = {
  en: "English",
  ru: "Русский",
  es: "Español",
  fr: "Français",
  de: "Deutsch",
  jp: "日本語",
  ar: "العربية",
  tk: "Türkçe",
  cn: "简体中文",
  uz: "O'zbekcha",
  uz_to: "Ўзбекча",
};

export const SUPPORT_LOCALES_NAMES_SLUG = {
  en: "English",
  ru: "Русский",
  es: "Español",
  fr: "Français",
  de: "Deutsch",
  jp: "Japanese",
  ar: "Arabic",
  tk: "Türkçe",
  cn: "Chinese",
  uz: "O'zbekcha",
  uz_to: "Ўзбекча",
};

// // tg channels for all languages
// export const CHANNELS = {
//   'en': 'uzeng',
//   'ru': 'uzru',
//   'uz': 'uz',
//   'uz-to': 'uzb',
// }

export const EMAIL = "info@intersoft.uz"

export const DEV_TOOLS = [
  {
    name: "JavaScript",
    icon: 'javascript.svg',
  },
  {
    name: "TypeScript",
    icon: 'typescript.svg',
  },
  {
    name: "Vue",
    icon: 'vue.svg',
  },
  {
    name: "React",
    icon: 'react.svg',
  },
  {
    name: "Flutter",
    icon: 'flutter.svg',
  },
  {
    name: "Dart",
    icon: 'dart.svg',
  },
  {
    name: "Python",
    icon: 'python.svg',
  },
  {
    name: "Nginx",
    icon: 'nginx.svg',
  },
  {
    name: "Postgres",
    icon: 'postgres.svg',
  },
  {
    name: "Redis",
    icon: 'redis.svg',
  },
  {
    name: "Django",
    icon: 'django.svg',
  },
];
