import {
  createRouter,
  createWebHistory,
  createMemoryHistory,
} from "vue-router";
import {
  getBrowserLocale,
  DEFAULT_LOCALE,
  i18n,
  loadLocaleMessages,
  setI18nLanguage,
} from "../i18n";
import { SUPPORT_LOCALES } from "@/utils/constants";

const routes = [];

const childRoutes = [
  {
    path: "",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "about",
    name: "AboutUs",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "portfolio",
    name: "Portfolio",
    component: () =>
      import(/* webpackChunkName: "portfolio" */ "../views/Portfolio.vue"),
  },
  {
    path: "services",
    name: "Services",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/Services.vue"),
  },
  {
    path: "contacts",
    name: "Contacts",
    component: () =>
      import(/* webpackChunkName: "contacts" */ "../views/Contacts.vue"),
  },
  {
    path: "price",
    name: "Price",
    component: () =>
      import(/* webpackChunkName: "price" */ "../views/Price.vue"),
  },
  {
    path: "development",
    name: "Development",
    component: () =>
      import(/* webpackChunkName: "development" */ "../views/Development.vue"),
  },
  {
    path: "application",
    name: "Application",
    component: () =>
      import(/* webpackChunkName: "application" */ "../views/Application.vue"),
  },
  {
    path: "detail/:slug",
    name: "Detail",
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/ProjectDetail.vue"),
  },
];

const routeNames = [];

const checkIfArrayIsUnique = (myArray) => {
  return myArray.length === new Set(myArray).size;
};

const recursiveChildren = (locale, children) => {
  if (!children || children.length === 0) {
    return;
  }
  const recChildren = [];
  children.forEach((child) => {
    routeNames.push(child.name);
    recChildren.push({
      ...child,
      name: `${locale}_${child.name}`,
      children: recursiveChildren(locale, child.children),
    });
  });
  return recChildren;
};

SUPPORT_LOCALES.forEach((locale) => {
  routes.unshift({
    path: `/${locale === DEFAULT_LOCALE ? "" : locale}`,
    component: {
      template: "<router-view></router-view>",
    },
    children: recursiveChildren(locale, childRoutes),
  });
});

// routes.push({
//   path: "/:catchAll(.*)",
//   name: "404",
//   component: () => import("@/views/404.vue"),
// });

if (checkIfArrayIsUnique(routeNames))
  throw new Error("Route names must be unique");

const isServer = typeof window === "undefined";
const history = isServer ? createMemoryHistory() : createWebHistory();

const beforeEach = async (to, from, next) => {
  if (!isServer) {
    window?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  let paramsLocale;
  SUPPORT_LOCALES.forEach((locale) => {
    const regex = new RegExp(
      `/${locale}/${locale.length + 1 === to.path.length ? `|/${locale}` : ""}`
    );
    let m;
    if ((m = regex.exec(to.path)) !== null) {
      paramsLocale = m[0].slice(1, locale.length + 1);
    }
  });

  paramsLocale = paramsLocale === undefined ? DEFAULT_LOCALE : paramsLocale;

  const newLocale = getBrowserLocale();

  // use locale if paramsLocale is not in SUPPORT_LOCALES

  if (!SUPPORT_LOCALES.includes(paramsLocale)) {
    return next({ name: `${newLocale}_Home` });
  }

  // load locale messages
  if (!i18n.global.availableLocales.includes(paramsLocale)) {
    await loadLocaleMessages(i18n, paramsLocale);
  }
  // set i18n language
  setI18nLanguage(i18n, paramsLocale);

  return next();
};

export default function () {
  const router = createRouter({
    history,
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        // return savedPosition
        return { top: 0 };
      } else {
        return { top: 0 };
      }
    },
  });

  router.beforeEach(beforeEach);
  return router;
}
