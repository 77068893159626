const DEBUG_URL = "https://api.radiants.uz/";

export const BASE_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.radiants.uz/"
    : DEBUG_URL;

const API_V1 = "/api/v1";
import { i18n } from "@/i18n";

export async function getPortfolio(cb) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/radiants/portfolio/`)
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}

export async function getTags(cb) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/radiants/tags/`)
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}

export async function getDetail(cb, slug) {
  fetch(
    `${BASE_URL}${i18n.global.locale.value}${API_V1}/radiants/portfolio/${slug}`
  )
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}

export async function getTeammates(cb) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/radiants/teammates/`)
    .then((res) => {
      return res.json();
    })
    .then((json) => {
      cb(json);
    });
}

export async function sendCallback(cb, form, err) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/radiants/callback/`, {
    method: "POST",
    body: form,
  })
    .catch((e) => {
      err(e);
    })
    .then(async (res) => {
      if (!res.ok) throw err(res.json());
      return await cb(res.json());
    });
}

export async function sendFeedback(cb, form, err) {
  fetch(`${BASE_URL}${i18n.global.locale.value}${API_V1}/radiants/feedback/`, {
    method: "POST",
    body: form,
  })
    .catch((e) => {
      err(e);
    })
    .then(async (res) => {
      if (!res.ok) throw err(res.json());
      return await cb(res.json());
    });
}