import * as apiV1 from "@/network/v1/index";

// export const getLatestNews = ({ state, commit }, { pageSize, cb = () => { }, category, load = 1 }) => {
//     apiV2.getLatestNews((data, count) => {
//         for (let i = 1; i <= load; i++) {
//             commit('setAllnews', data.slice((i - 1) * pageSize, i * pageSize))
//             commit('incrementCurrentPage')
//         }
//         commit('setNewsCount', count)
//         cb()
//     }, state.currentPage, pageSize * load, category)
// }

export const setSendModal = ({ commit }, { value }) => {
  commit("setSendModal", value);
};

export const setSuccessModal = ({ commit }, { value }) => {
  commit("setSuccessModal", value);
};

export const getPortfolio = ({ commit }, { cb = () => {} }) => {
  apiV1.getPortfolio((data) => {
    commit("setPortfolio", data);
    cb();
  });
};

export const getTags = ({ commit }, { cb = () => {} }) => {
  apiV1.getTags((data) => {
    commit("setTags", data);
    cb();
  });
};

export const getDetail = ({ commit }, { cb = () => {}, slug }) => {
  apiV1.getDetail((data) => {
    commit("setDetail", data);
    cb();
  }, slug);
};

export const getTeammates = ({ commit }, { cb = () => {} }) => {
  apiV1.getTeammates((data) => {
    commit("setTeammates", data);
    cb();
  });
};

export const sendCallback = ({ commit }, { cb = () => {}, form, err }) => {
  apiV1.sendCallback(
    (data) => {
      commit("setCallbackSuccess", data);
      cb(data);
    },
    form,
    err
  );
};

export const sendFeedback = ({ commit }, { cb = () => {}, form, err }) => {
  apiV1.sendFeedback(
    (data) => {
      commit("setSuccessModal", true);
      cb(data);
    },
    form,
    err
  );
};

export const setCallback = ({ commit }, { value }) => {
  commit("setCallback", value);
};
