export default {
  //     removeActiveArtivle(state) {
  //         state.activeArticle = fakeArticle
  //     },

  setSendModal(state, value) {
    state.modals.sendModal = value;
  },

  setSuccessModal(state, value) {
    state.modals.successModal = value;
  },

  setPortfolio(state, value) {
    state.portfolio = value;
  },

  setTags(state, value) {
    state.tags = value;
  },

  setDetail(state, value) {
    state.detail = value;
  },

  setTeammates(state, value) {
    state.teammates = value;
  },

  setCallback(state, value) {
    state.callback = value;
  },

  setCallbackSuccess(state, value) {
    state.callbackSuccess = value;
  },
};
