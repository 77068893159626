<template>
  <v-header />
  <main>
    <router-view v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" :key="$route.name" />
      </transition>
    </router-view>
  </main>
  <contact-us
    v-if="
      $route.name !== `${i18n.global.locale.value}_Price` &&
      $route.name !== `${i18n.global.locale.value}_Contacts` &&
      $route.name !== `${i18n.global.locale.value}_Development` &&
      $route.name !== `${i18n.global.locale.value}_Application`
    "
  />
  <v-footer />
  <!-- <div v-if="!isTablet" class="cursor" ref="cursor">
    <div></div>
  </div> -->
  <div class="bg-gradient"></div>
  <RequestModal />
</template>

<script setup>
import VHeader from "@/components/Header.vue";
import VFooter from "@/components/Footer.vue";
import ContactUs from "@/components/ContactUs.vue";
import RequestModal from "@/components/RequestModal.vue";
import { useLanguage } from "@/utils/language";
import { onMounted, ref } from "vue";
const { i18n } = useLanguage();

// const cursor = ref(null);

const isTablet = ref();

const handleResize = () => {
  isTablet.value = window.innerWidth <= 1024;
};

if (typeof window != undefined) {
  isTablet.value = window.innerWidth <= 1024;
  window.addEventListener("resize", handleResize);
}

onMounted(() => {
  // addEventListener("mousemove", (e) => {
  //   cursor.value.setAttribute(
  //     "style",
  //     "top: " + (e.pageY - 5) + "px; left: " + (e.pageX - 5) + "px;"
  //   );
  //   // cursor.value.style.top = e.pageY - 5 + "px";
  //   // cursor.value.style.left = e.pageX - 5 + "px";
  // });
});
</script>

<style lang="scss">
.bg-gradient {
  position: fixed;
  top: -20%;
  left: 0;
  right: 0;
  bottom: -20%;
  z-index: -5;
  background: linear-gradient(var(--blue-1), var(--primary-2));
}

.cursor {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: 0px 0px 7000px 200px rgba(129, 19, 255, 0.4);
  position: absolute;
  z-index: -2;
  background: #fff;
  filter: blur(0.8px);
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.5);
  will-change: all;

  div {
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 6.25rem;
    box-shadow: 0px 0px 100px 50px rgba(144, 0, 255, 0.9);
  }
}

:root {
  // light
  --primary-font-color: #121212;
  --secondary-font-color: #fff;
  --highlight-font-color: #007bff;
  --primary-bg: 255, 255, 255;
  --secondary-bg: 18, 18, 18;
  --background-color: #cecece;
  --foreground-color: #121212;

  // dark
  --primary-font-color: #fff;
  --secondary-font-color: #121212;
  --highlight-font-color: #0063ce;
  --primary-bg: 18, 18, 18;
  --secondary-bg: 255, 255, 255;
  --highlight-bg-color: #0056ba;
  --background-color: #121212;
  --foreground-color: #fff;

  --white: #ffffff;

  --primary-1: #ff592c;
  --primary-2: #9757d7;
  --primary-3: #ef466f;
  --primary-4: #45b36b;

  --secondary-1: #3772ff;
  --secondary-2: #e4d7cf;
  --secondary-3: #ffd166;
  --secondary-4: #cdb4db;

  --neutrals-1: #141416;
  --neutrals-2: #23262f;
  --neutrals-3: #353945;
  --neutrals-4: #777e91;
  --neutrals-5: #b1b5c4;
  --neutrals-6: #e6e8ec;
  --neutrals-7: #f4f5f6;
  --neutrals-8: #fcfcfd;

  --gray-1: #6b6b6b;
  --gray-2: #808080;
  --grey-3: #ababab;

  --blue-1: #0a0b1d;

  --bg-2: #575757;
  --bg-3: #424242;

  color: var(--primary-font-color);
  font-size: max(16px, 0.73vw);
}

img[alt] {
  color: transparent;
}

img:after {
  font-weight: 900;
  font-family: "Font Awesome 5 Pro";
  content: "\f071"" Broken Image of " attr(alt);
  display: block;
  font-size: 16px;
  font-style: normal;
  color: rgb(100, 100, 100);

  position: absolute;
  top: 40%;
  width: 100%;
  text-align: center;
}

b,
strong {
  font-weight: bold;
}

blockquote {
  border-left: 0.3em solid grey;
  margin-top: 1em;
  margin-bottom: 1em;
  font-style: italic;
  p {
    padding: 0.6em 2em;
  }
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

i,
em {
  font-style: italic;
}

input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

* {
  box-sizing: border-box;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  background-color: yellow;
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background-color: var(--foreground-color);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

html {
  background-color: var(--blue-1);
  font-family: "Inter", sans-serif;
  height: 100%;
}

body {
  height: 100%;
}

main {
  flex: 1 0 auto;
}

#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  // transition: all 0.6s cubic-bezier(0.45, 0, 0.55, 1);
  // transition: all 0.4s ease-in-out;
}

img {
  background-color: none;
  object-fit: cover;
}

.limited-text {
  --snup: 3;
  display: -webkit-box;
  -webkit-line-clamp: var(--snup);
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s ease !important;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.container {
  max-width: 75rem;
  padding: 0 0.9375rem;
  margin: 0 auto;
}

.hero {
  font-weight: 700;
  font-size: 4.5em;
  letter-spacing: -0.02em;
}

.gradient-text {
  background-clip: text;
  background: -webkit-linear-gradient(0deg, #9757d7, #eb34eb, #fa98fa);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.w {
  &400 {
    font-weight: 400;
  }
  &500 {
    font-weight: 500;
  }
  &600 {
    font-weight: 600;
  }
  &700 {
    font-weight: 700;
  }
}

.headline {
  &-1 {
    font-size: 3.375em;
  }

  &-2 {
    font-size: 3.4em;
    line-height: 3rem;
  }

  &-3 {
    font-size: 1.25em;
    line-height: 1.5rem;
  }

  &-4 {
    font-size: 2em;
    line-height: 2.5rem;
  }

  &-5 {
    font-size: 1.5em;
    line-height: 2rem;
  }

  &-6 {
    font-size: 1.13em;
    line-height: 1.5rem;
  }

  &-7 {
    font-size: 2.5em;
    line-height: 3rem;
  }
}

.body {
  &-1 {
    font-weight: 400;
    font-size: 1.5em;
    line-height: 2em;
  }

  &-2 {
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
  }

  &-3 {
    font-weight: 400;
    font-size: 1.13em;
    line-height: 1.625rem;
  }
}

.caption {
  &-1 {
    font-weight: 400;
    font-size: 0.875em;
    line-height: 1.5em;
  }

  &-2 {
    font-weight: 400;
    font-size: 0.75em;
    line-height: 1.25em;
  }

  &-3 {
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
  }
}

.hairline {
  &-1 {
    font-size: 1em;
    line-height: 1em;
  }

  &-2 {
    font-size: 0.75em;
    line-height: 0.75em;
  }
}

.button {
  &-1 {
    font-size: 1em;
    line-height: 1em;
    transition: all 0.3s ease;
    cursor: pointer;
  }

  &-2 {
    font-size: 0.875em;
    line-height: 1em;
    cursor: pointer;
    transition: all 0.3s ease;
  }
}

.primary-button {
  padding: 0.625rem 1.5625rem;
  font-weight: 500;
  font-size: 1.125em;
  line-height: 1.25em;
  text-align: center;
  background: var(--white);
  border-radius: 3.125rem;
  color: var(--primary-2);
  transition: all 0.3s ease;
  border: 0.125rem solid var(--primary-2);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    margin-left: 0.5rem;
    path {
      fill: var(--primary-2);
    }
  }

  &:hover {
    background: var(--primary-2);
    border: 0.125rem solid var(--gray-2);
    color: var(--white);

    svg {
      path {
        fill: var(--white);
      }
    }
  }
}

.primary-input {
  padding: 0.625rem 1rem;
  border-radius: 3.125rem;
  background: #2e2e2e;
  border: 0.0625rem solid var(--gray-1);
  font-weight: 400;
  font-size: 1em;
  line-height: 1.5em;
  color: var(--white);

  &::placeholder {
    line-height: 1.5em;
    font-size: 1em;
    color: var(--grey-3);
  }

  &.error {
    border-color: var(--primary-3);
  }

  &.success {
    border-color: var(--primary-4);
  }
}

.error-message {
  width: 100%;
  color: var(--primary-3);
  margin-bottom: 1rem;
}

.primary-textarea {
  padding: 0.625rem 1rem;
  border-radius: 1.25rem;
  background: #2e2e2e;
  border: 0.0625rem solid var(--gray-1);
  font-weight: 400;
  font-size: 1em;
  line-height: 1.5em;
  color: var(--white);
  resize: vertical;
  max-height: 12.5rem;

  &.error {
    border-color: var(--primary-3);
  }

  &.success {
    border-color: var(--primary-4);
  }

  &::placeholder {
    line-height: 1.5em;
    font-size: 1em;
    color: var(--grey-3);
  }
}

.flex {
  display: flex;

  &.align-items {
    &-center {
      align-items: center;
    }
    &-end {
      align-items: flex-end;
    }
    &-start {
      align-items: start;
    }
  }

  &.f-center {
    justify-content: center;
    align-items: center;
  }

  &.f-space-between {
    justify-content: space-between;
  }

  &.f-justify-end {
    justify-content: flex-end;
  }

  &.f-wrap {
    flex-wrap: wrap;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.max-content {
  width: max-content;
}

.before-enter {
  --duration: 2s;
  // --trnasition-timing: cubic-bezier(0.075, 0.8, 0.165, 1.5);
  --trnasition-timing: cubic-bezier(0.175, 0.885, 0.32, 1);
  // transition: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  &.left-to-right,
  .left-to-right {
    transform: translateX(-12rem);
    opacity: 0;
    transition: all var(--duration) var(--trnasition-timing);
  }

  &.right-to-left,
  .right-to-left {
    transform: translateX(12rem);
    opacity: 0;
    transition: all var(--duration) var(--trnasition-timing);
  }

  &.bottom-to-top,
  .bottom-to-top {
    transform: translateY(12rem);
    opacity: 0;
    transition: all var(--duration) var(--trnasition-timing);
  }

  &.top-to-bottom,
  .top-to-bottom {
    transform: translateY(-12rem);
    opacity: 0;
    transition: all var(--duration) var(--trnasition-timing);
  }

  &.enter {
    &.left-to-right,
    .left-to-right {
      transform: translateX(0);
      opacity: 1;
    }

    &.right-to-left,
    .right-to-left {
      transform: translateX(0);
      opacity: 1;
    }

    &.bottom-to-top,
    .bottom-to-top {
      transform: translateX(0);
      opacity: 1;
    }

    &.top-to-bottom,
    .top-to-bottom {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @for $i from 1 through 30 {
    .delay-#{$i} {
      transition-delay: #{$i / 10}s;
    }
  }
}

.primary-bg {
  background: var(--blue-1);
  // box-shadow: 0px 0px 50px black;
}

@media screen and (max-width: 1336px) {
  .container {
    max-width: 60rem;
  }

  .headline {
    &-2 {
      font-size: 3rem;
    }
  }
}

@media screen and (max-width: 992px) {
  :root {
    font-size: 14px;
  }

  .container {
    padding: 0 2rem;
  }

  .headline {
    &-2 {
      font-size: 2.5rem;
    }
  }
}

@media screen and (max-width: 768px) {
  :root {
    font-size: 12px;
  }

  .headline {
    &-2 {
      font-size: 2rem;
    }
  }
}

@media screen and (max-width: 375px) {
  :root {
    font-size: 10px;
  }
}
</style>
