import { createStore } from "vuex";
import * as getters from "./getters";
import * as actions from "./actions";
import mutations from "./mutations";

const state = {
  categories: null,
  modals: {
    sendModal: false,
    successModal: false,
  },
  portfolio: [],
  tags: [],
  detail: {},
  teammates: [],
  callback: {
    name: '',
    phone: '+998',
  },
  callbackSuccess: null
};

export default function () {
  return createStore({
    state,
    getters,
    actions,
    mutations
  });
}
