<template>
  <header class="header" v-scrollanimation>
    <div class="container">
      <div class="header__inner">
        <div class="header__left flex">
          <router-link
            @click="menuActive = false"
            :to="$i18nRoute({ name: 'Home' })"
            class="logo flex"
          >
            <img class="logo__img" src="@/assets/logo_new.svg" alt="Logo" />
          </router-link>
          <nav class="nav">
            <router-link
              :to="$i18nRoute({ name: route })"
              class="nav__link nav__link_active button-1 w500"
              v-for="route in routes"
              :key="route"
              >{{ $t(`routes.${route}`) }}
            </router-link>
          </nav>
        </div>
        <div class="header__right flex">
          <button
            v-if="!isMobile"
            class="menu__button primary-button"
            @click="openModal()"
          >
            {{ $t(`routes.OrderProject`) }}
          </button>
          <button
            class="menu__burger"
            :class="{ active: menuActive }"
            @click="menuActive = !menuActive"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </div>
    <div class="menu" :class="{ active: menuActive }">
      <div class="menu__container flex f-space-between flex-column">
        <div></div>
        <nav class="nav flex flex-column">
          <div
            class="link-wrap"
            v-for="(route, index) in routes"
            :class="{
              top:
                Math.floor(routes.length / 2) != index &&
                routes.length / 2 > index,
              bottom: routes.length / 2 < index,
              center: Math.floor(routes.length / 2) == index,
              delay: index == 0 || routes.length - 1 == index,
            }"
            :key="route"
          >
            <router-link
              @click="menuActive = false"
              :to="$i18nRoute({ name: route })"
              class="nav__link button-1 w500"
              >{{ $t(`routes.${route}`) }}
            </router-link>
          </div>
          <div class="bottom delay link-wrap delay-5">
            <button
              v-if="isMobile"
              class="menu__button primary-button"
              @click="openModal()"
            >
              {{ $t(`routes.OrderProject`) }}
            </button>
          </div>
        </nav>
        <div class="social">
          <a class="social__link bottom-to-top delay-3">
            <svg
              width="2rem"
              height="2rem"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M32 16C32 24.84 24.84 32 16 32C7.16 32 0 24.84 0 16C0 7.16 7.16 0 16 0C24.84 0 32 7.16 32 16ZM13.072 24.44C20.168 24.44 24.048 18.56 24.048 13.464C24.048 13.296 24.048 13.128 24.04 12.968C24.792 12.424 25.448 11.744 25.968 10.968C25.28 11.272 24.536 11.48 23.752 11.576C24.552 11.096 25.16 10.344 25.448 9.44C24.704 9.88 23.88 10.2 23 10.376C22.296 9.624 21.296 9.16 20.184 9.16C18.056 9.16 16.328 10.888 16.328 13.016C16.328 13.32 16.36 13.616 16.432 13.896C13.224 13.736 10.384 12.2 8.48 9.864C8.152 10.432 7.96 11.096 7.96 11.8C7.96 13.136 8.64 14.32 9.68 15.008C9.048 14.992 8.456 14.816 7.936 14.528C7.936 14.544 7.936 14.56 7.936 14.576C7.936 16.448 9.264 18 11.032 18.36C10.712 18.448 10.368 18.496 10.016 18.496C9.768 18.496 9.528 18.472 9.288 18.424C9.776 19.96 11.2 21.072 12.888 21.104C11.568 22.136 9.904 22.752 8.096 22.752C7.784 22.752 7.48 22.736 7.176 22.696C8.864 23.8 10.896 24.44 13.072 24.44Z"
                fill="white"
              />
            </svg>
          </a>
          <a class="social__link bottom-to-top delay-4">
            <svg
              width="2rem"
              height="2rem"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M32 16.0978C32 7.20722 24.8366 0 16 0C7.16344 0 0 7.20722 0 16.0978C0 24.1325 5.85094 30.7924 13.5 32V20.751H9.4375V16.0978H13.5V12.5512C13.5 8.51673 15.8888 6.2882 19.5434 6.2882C21.2934 6.2882 23.125 6.60261 23.125 6.60261V10.5642H21.1075C19.12 10.5642 18.5 11.8051 18.5 13.0794V16.0978H22.9375L22.2281 20.751H18.5V32C26.1491 30.7924 32 24.1325 32 16.0978Z"
                fill="white"
              />
            </svg>
          </a>
          <a class="social__link bottom-to-top delay-5">
            <svg
              width="2rem"
              height="2rem"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16" cy="16" r="16" fill="white" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.9375 8.6875H12.0625C10.1985 8.6875 8.6875 10.1985 8.6875 12.0625V19.9375C8.6875 21.8015 10.1985 23.3125 12.0625 23.3125H19.9375C21.8015 23.3125 23.3125 21.8015 23.3125 19.9375V12.0625C23.3125 10.1985 21.8015 8.6875 19.9375 8.6875ZM12.0625 7C9.26656 7 7 9.26656 7 12.0625V19.9375C7 22.7334 9.26656 25 12.0625 25H19.9375C22.7334 25 25 22.7334 25 19.9375V12.0625C25 9.26656 22.7334 7 19.9375 7H12.0625ZM16 19.375C17.864 19.375 19.375 17.864 19.375 16C19.375 14.136 17.864 12.625 16 12.625C14.136 12.625 12.625 14.136 12.625 16C12.625 17.864 14.136 19.375 16 19.375ZM16 21.0625C18.7959 21.0625 21.0625 18.7959 21.0625 16C21.0625 13.2041 18.7959 10.9375 16 10.9375C13.2041 10.9375 10.9375 13.2041 10.9375 16C10.9375 18.7959 13.2041 21.0625 16 21.0625ZM20.7921 12.3329C21.4135 12.3329 21.9171 11.8292 21.9171 11.2079C21.9171 10.5865 21.4135 10.0829 20.7921 10.0829C20.1708 10.0829 19.6671 10.5865 19.6671 11.2079C19.6671 11.8292 20.1708 12.3329 20.7921 12.3329Z"
                fill="#2E2E2E"
              />
            </svg>
          </a>
          <a class="social__link bottom-to-top delay-6">
            <svg
              width="2rem"
              height="2rem"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="16" cy="16" r="16" fill="white" />
              <path
                d="M26.54 10.67C26.4157 10.2019 26.1706 9.77468 25.8293 9.43113C25.4879 9.08758 25.0623 8.83976 24.595 8.7125C22.875 8.25 16 8.25 16 8.25C16 8.25 9.125 8.25 7.405 8.7125C6.93771 8.83976 6.51207 9.08758 6.17071 9.43113C5.82935 9.77468 5.58426 10.2019 5.46 10.67C5 12.3975 5 16 5 16C5 16 5 19.6025 5.46 21.33C5.58426 21.7981 5.82935 22.2253 6.17071 22.5689C6.51207 22.9124 6.93771 23.1602 7.405 23.2875C9.125 23.75 16 23.75 16 23.75C16 23.75 22.875 23.75 24.595 23.2875C25.0623 23.1602 25.4879 22.9124 25.8293 22.5689C26.1706 22.2253 26.4157 21.7981 26.54 21.33C27 19.6025 27 16 27 16C27 16 27 12.3975 26.54 10.67Z"
                fill="#2E2E2E"
              />
              <path
                d="M13.75 19.271L19.5 15.9998L13.75 12.7285V19.271Z"
                fill="white"
              />
            </svg>
          </a>
          <a class="social__link bottom-to-top delay-7">
            <svg
              width="2rem"
              height="2.0625rem"
              viewBox="0 0 32 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 0C7.16379 0 0 7.16379 0 16C0 22.7819 4.21399 28.5761 10.1663 30.907C10.0214 29.6428 9.90288 27.6938 10.2189 26.3111C10.5086 25.0601 12.0889 18.3572 12.0889 18.3572C12.0889 18.3572 11.6148 17.3959 11.6148 15.9868C11.6148 13.7613 12.9053 12.1021 14.5119 12.1021C15.8815 12.1021 16.5399 13.1292 16.5399 14.3539C16.5399 15.7235 15.6708 17.7778 15.2099 19.6872C14.828 21.2807 16.0132 22.5844 17.5802 22.5844C20.4247 22.5844 22.6107 19.5819 22.6107 15.2626C22.6107 11.4305 19.8584 8.7572 15.921 8.7572C11.3646 8.7572 8.69136 12.1679 8.69136 15.6971C8.69136 17.0667 9.21811 18.5416 9.87654 19.3449C10.0082 19.5029 10.0214 19.6477 9.98189 19.8058C9.86337 20.3062 9.58683 21.3992 9.53416 21.623C9.46831 21.9128 9.29712 21.9786 8.99424 21.8337C6.99259 20.8988 5.74156 17.9885 5.74156 15.6313C5.74156 10.5877 9.40247 5.95226 16.316 5.95226C21.8601 5.95226 26.1794 9.90288 26.1794 15.1967C26.1794 20.7144 22.7029 25.1523 17.8831 25.1523C16.2634 25.1523 14.7358 24.3095 14.2222 23.3086C14.2222 23.3086 13.4189 26.3638 13.2214 27.1144C12.8658 28.5103 11.8914 30.2486 11.2329 31.3152C12.7342 31.7761 14.3144 32.0263 15.9737 32.0263C24.8099 32.0263 31.9737 24.8626 31.9737 16.0263C32 7.16378 24.8362 0 16 0Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
const routes = ref(["AboutUs", "Portfolio", "Services", "Contacts", "Price"]);

const store = useStore();

const menuActive = ref(false);

const isMobile = ref();

const handleResize = () => {
  isMobile.value = window.innerWidth <= 375;
};

if (typeof window != undefined) {
  isMobile.value = window.innerWidth <= 375;
  window.addEventListener("resize", handleResize);
}

onMounted(() => {
  watch(() => {
    if (menuActive.value) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [menuActive]);
});

const openModal = () => {
  store.commit("setSendModal", true);
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
}

.header {
  position: relative;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25rem 0;
  }

  .menu {
    display: none;

    &__burger {
      display: none;
    }
  }

  .logo {
    margin-right: 4.375rem;
    position: relative;
    z-index: 15;
  }

  &__right {
    gap: 1.375rem;
    position: relative;
    z-index: 15;
  }

  .logo__img {
    width: 10rem;
  }

  .nav {
    display: flex;

    &__link {
      transition: all 0.3s ease;
      color: var(--neutrals-8);
      padding: 0.9375rem;

      &:hover {
        color: var(--secondary-1);
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .header {
    .logo {
      margin-right: 1.25rem;
      img {
        width: 7.5rem;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .header {
    .nav {
      &__link {
        padding: 0.7rem;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .header {
    width: 100%;
    font-size: 13px;

    .container {
      .nav {
        display: none;
      }
    }

    .menu {
      display: block;
      position: fixed;
      top: 0;
      left: -2rem;
      right: -2rem;
      z-index: 10;
      background: var(--blue-1);
      height: 100vh;
      overflow: hidden;
      transition: all 0.5s ease;
      opacity: 0;
      pointer-events: none;

      .social {
        margin-bottom: 15vh;
        transform: translateY(9.38rem);
        opacity: 0;
        transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);

        &__link {
          margin: 1rem;

          svg {
            width: 3.5rem;
            height: 3.5rem;
          }
        }
      }

      &__container {
        height: 100%;
      }

      &-top-to-bottom {
        transform: translateY(-5rem);
        opacity: 0;
      }

      &.active {
        opacity: 1;
        pointer-events: all;

        .social {
          transform: translateY(0);
          opacity: 1;
        }

        .nav {
          .link-wrap {
            transform: translateY(0) !important;
            opacity: 1 !important;

            &.delay {
              transition-delay: 0.1s;
            }
          }
        }
      }

      .nav {
        flex-direction: column;
        font-size: 20px;

        .link-wrap {
          transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);

          &.bottom {
            transform: translateY(9.38rem);
            opacity: 0;
          }
          &.top {
            transform: translateY(-9.38rem);
            opacity: 0;
          }
          &.center {
            opacity: 0;
          }
        }

        &__link {
          padding: 3vh 2rem;
          border-radius: 0.63rem;
          display: block;
        }
      }

      &__burger {
        display: flex;
        flex-direction: column;
        height: 2rem;
        justify-content: space-between;
        background: none;
        padding: 0 0.5rem;

        &.active {
          span {
            &:nth-child(1) {
              transform: rotate(45deg) translate(0.63rem, 0.6rem);
            }

            &:nth-child(2) {
              transform: translateX(1.25rem);
              opacity: 0;
            }

            &:nth-child(3) {
              transform: rotate(-45deg) translate(0.63rem, -0.6rem);
            }
          }
        }

        span {
          width: 2rem;
          height: 0.2rem;
          display: block;
          background: var(--white);
          transition: all 0.4s ease-in-out;
          opacity: 1;
        }
      }
    }
  }
}
</style>
