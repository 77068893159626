export const sendModal = state => state.modals.sendModal

export const successModal = state => state.modals.successModal

export const portfolio = state => state.portfolio;

export const tags = state => state.tags;

export const detail = state => state.detail;

export const teammates = state => state.teammates;

export const callback = state => state.callback;

export const callbackSuccess = state => state.callbackSuccess;