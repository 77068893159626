<template>
  <div class="contact-us" v-scrollanimation>
    <div class="container">
      <div class="contact-us__inner flex f-wrap">
        <div class="contact-us__column">
          <h2 class="contact-us__title headline-1 w700 left-to-right">
            Давайте обсудим ваш проект
          </h2>
        </div>
        <div class="contact-us__column flex f-justify-end">
          <div class="pros">
            <h3 class="pros__title hairline-1 w700 right-to-left">
              Свяжитесь с нами и получите +
            </h3>
            <ul class="pros__list list">
              <li
                class="list__item flex align-items-center right-to-left delay-2"
              >
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.3382 8.33823L10.4992 20.1773L5.66016 15.3382L6.58823 14.4102L10.4992 18.3211L21.4102 7.41016L22.3382 8.33823Z"
                    fill="white"
                  />
                </svg>
                <span> Бесплатную консультацию </span>
              </li>
              <li
                class="list__item flex align-items-center right-to-left delay-3"
              >
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.3382 8.33823L10.4992 20.1773L5.66016 15.3382L6.58823 14.4102L10.4992 18.3211L21.4102 7.41016L22.3382 8.33823Z"
                    fill="white"
                  />
                </svg>
                <span> Расчет цены </span>
              </li>
              <li
                class="list__item flex align-items-center right-to-left delay-4"
              >
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.3382 8.33823L10.4992 20.1773L5.66016 15.3382L6.58823 14.4102L10.4992 18.3211L21.4102 7.41016L22.3382 8.33823Z"
                    fill="white"
                  />
                </svg>
                <span> Подбор услуги </span>
              </li>
              <li
                class="list__item flex align-items-center right-to-left delay-5"
              >
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.3382 8.33823L10.4992 20.1773L5.66016 15.3382L6.58823 14.4102L10.4992 18.3211L21.4102 7.41016L22.3382 8.33823Z"
                    fill="white"
                  />
                </svg>
                <span> Скидку на проект </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="send flex f-wrap">
          <div class="send__input_wrap left-to-right delay-1">
            <input
              type="text"
              class="send__input primary-input"
              placeholder="Введите свой номер"
              v-model="phone"
            />
          </div>
          <div class="left-to-right delay-2">
            <button @click="openModal()" class="send__button primary-button">
              <span>Отправить</span>
              <svg
                width="1.3125rem"
                height="1.25rem"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.1545 4.67969L16.1425 10.0003L11.1545 15.3209L10.4705 14.6797L14.418 10.469H5.5V9.53153H14.418L10.4705 5.32088L11.1545 4.67969Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from "vue";
import { useStore } from "vuex";

defineProps({
  isBackground: Boolean,
});

const store = useStore();

const phone = ref('')

phone.value = computed(() => store.getters.callback.phone).value;

const openModal = () => {
  console.log(phone.value)
  store.commit("setCallback", { name: "", phone: phone.value });
  console.log(computed(() => store.getters.callback.phone).value)
  store.commit("setSendModal", true);
};
</script>

<style lang="scss" scoped>
.contact-us {
  position: relative;

  &__inner {
    padding: 5rem 0;
  }

  &__title {
    color: var(--white);
    margin-bottom: 1.875rem;
    margin-right: 1rem;
  }

  &__column {
    flex: 0 0 50%;
  }

  .send {
    &__input {
      margin-right: 1rem;
    }
  }

  .pros {
    flex: 2;
    padding-top: 1.2rem;

    &__title {
      margin-bottom: 1.25rem;
    }

    .list {
      &__item {
        font-size: 1.125rem;
        line-height: 1.625rem;
        margin-bottom: 0.625rem;

        span {
          margin-left: 1.25rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1336px) {
  .contact-us {
    .pros {
      width: max-content;
    }

    &__column {
      margin-bottom: 1.5rem;

      &:last-child {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .contact-us {
    .send {
      &__input {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
      }

      &__button {
        margin: 0.5rem 0;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .contact-us {
    &__inner {
      padding: 3rem 0;
    }

    &__column {
      flex: 0 0 100%;
    }

    .pros {
      font-size: 17px;

      .list {
        &__item {
          span {
            font-size: 1.3em;
          }
        }
      }
    }

    .send {
      font-size: 14px;
      max-width: 20rem;

      &__input {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1rem;

        &_wrap {
          width: 100%;
        }
      }

      &__button {
        width: 100%;
      }
    }
  }
}
</style>
